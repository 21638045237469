import React, {useEffect, useState, useRef, lazy} from "react";
import { Container, Modal } from "react-bootstrap";
import { navigate } from "gatsby";
import { NumericFormat } from "react-number-format";
import api from "../../actions/riveroapi";
import SEO from "../../components/seo";
import FormularioSalesforce from "../../components/widgets/formularios/FormularioSalesforce";
import TagManager from "react-gtm-module";
import Footer from "../../components/footer";

const Layout = lazy(() => import('../../components/layouts/LayoutNuevos'));
const BotonesNav = lazy(() => import('../../components/widgets/botonesNav'))

const Principal = lazy(() => import('../../components/VistasLanding/Principal'));
const Cotiza = lazy(() => import('../../components/VistasLanding/Cotiza'));
const Caracteristicas = lazy(() => import('../../components/VistasLanding/Caracteristicas'));
const Galeria = lazy(() => import('../../components/VistasLanding/Galeria'));
const Versiones = lazy(() => import('../../components/VistasLanding/Versiones'));
const InformacionTecnica = lazy(() => import('../../components/widgets/info-tecnica'));

let tagManagerArgs;

export default function AutosLanding(props){

    const path_auto = props.location.pathname;
    var splitPathAuto = path_auto.split("&");
    var auto = path_auto.split("-");
    var make = auto[0].split(/[-[\]{}()*+?.,\\^$|#\s]/g, " ");
    //let splitUrl = window.location.pathname.split("/");

    if (typeof window !== 'undefined') {
        // Código que usa localStorage
        var paramsPublicidad = localStorage.getItem('paramsPublicidad') || '';
        //var numeroDeEntradas = window.history.length;
        // Resto del código...
    }
    let arrayParams = (paramsPublicidad || '').split(/[?&]+/);

    const refContainer = useRef();
    const refPrincipal = useRef();
    const refCotiza = useRef();
    const refCaracteristicas = useRef();
    const refGaleria = useRef();
    const refVersiones = useRef();

    const [ car, setCar ] = useState();
    const [ color, setColor ] = useState();
    const [ imagen, setImage ] = useState();
    const [ enganche, setEnganche ] = useState();
    const [ verFeatur, setVerFeatur ] = useState();
    const [ mesValor, setMesValor ] = useState(60);
    const [ pagoMensual, setPagoMensual ] = useState();
    const [ navActivo, setNavActivo ] = useState("Principal");
    const [ featuresVersion, setFeaturesVersion ] = useState();     
    const [ cargaPMensual, setCargaPMensual ] = useState(false);
    const [ menualPrincipal, setMensualPrincipal ] = useState();
    const [ enganchePrincipal, setEnganchePrincipal ] = useState();
    const [ valorPorcentaje, setValorPorcentaje ] = useState(25);
    const [ background, setBackground ] = useState('00');
    const [ specsUrl, setSpecsUrl ] = useState(null);
    const [ manualUrl, setManualUrl ] = useState(null);
    const [ catalogUrl, setCatalogUrl ] = useState(null);
    const [ clickPagina, setClickPagina ] = useState(6);

    const [ showModal, setShowModal ] = useState({proviene: "", show: false});

    const [ paramsURL, setParamsURL ] = useState({cid: "", ucid: "", utm_source: "", utm_medium: "", utm_campaign: "", cnname: "", utm_content: "", utm_term: ""});
    const [ versionSelect, setVersionSelect ] = useState({version: "", precio: "", id: ""});
    const [ urlContruct, setUrlContruct ] = useState("");
    const [ urlNavigation, setUrlNavigation ] = useState("");

    const [ promocion, setPromocion ] = useState();
    const [ promocionTabSelect, setPromocionTabSelect ] = useState();

    const checkUrl = async (url, setter) => {
        console.log(url, "CheckURL");
        fetch(url, {
          method: 'HEAD'
        }).then(r => {
          if (r.status === 200) {
            console.log(r.status, "Seter1");
            setter(url);
          } else {
            console.log(r.status, "Seter2");
          }
        });
      };

    useEffect(() => {
        //scrollTo('#container2')
        api.getCar(props.slug).then((response) => { 
            setCar(response);

            setFeaturesVersion(response.versions[0]?.features);
            setVerFeatur(response.versions[0]?.name);
    
            const landingUrl = "https://d3s2hob8w3xwk8.cloudfront.net/autos-landing";
            const pdfUrl = `${landingUrl}` + "/" + (response.make.toLowerCase()) + "/" + (response.model.replaceAll(" ", "-").toLowerCase() + "-" + response.year.replace(".", "")) + "/pdf";
            //console.log(pdfUrl, "PDFUrl");
/*             checkUrl(`${pdfUrl}/ficha-tecnica.pdf`, setSpecsUrl);
            checkUrl(`${pdfUrl}/manual.pdf`, setManualUrl);
            checkUrl(`${pdfUrl}/catalogo.pdf`, setCatalogUrl); */

            setSpecsUrl(`${pdfUrl}/ficha-tecnica.pdf`);
            setManualUrl(`${pdfUrl}/manual.pdf`);
            setCatalogUrl(`${pdfUrl}/catalogo.pdf`);

            api.getCarroPromocion((props.slug)).then((resp) => {
                setPromocion(resp[0])
                resp[0] ? setPromocionTabSelect("PROMOCION") : setPromocionTabSelect("ORIGINAL");
            }).catch((error) => console.log("Sucedio un error: ", error));
            

            let colorReplace = response.colors[0]?.name.replace(/ /g, "-");
            let versionNameReplace = response.versions[0]?.name.replace(/ /g, "-")
            let searchParams = props.location.search
            let arrayParams = searchParams.split(/[?&=]+/); 
 
            var splitPropsParams = props.location?.state?.paramsPublicidad?.split(/[?&=]+/);
            var propsParamsClean = props.location?.state?.paramsPublicidad?.split("?"); 

            let objParams = {};

            const paramsSearch = new URLSearchParams(searchParams);

            let source = paramsSearch.get("utm_source");
            let medium = paramsSearch.get("utm_medium");
            let campaign = paramsSearch.get("utm_campaign");
            let cnname = paramsSearch.get("cnname");
            let content = paramsSearch.get("utm_content");
            let term = paramsSearch.get("utm_term");
            let gadSource = paramsSearch.get("gad_source");
            let gclId = paramsSearch.get("gclid")
            //console.log("searchParams: ", searchParams);
                
            // Inicia sin parametros y su función es agregarlos
            if(arrayParams[1] === undefined || arrayParams[1] === ""){
                /* console.log("No tengo nada, sucede lo siguiente: ", ); */
                setColor(response.colors[0].name);

                setImage(response.colors[0].image);

                setVersionSelect({
                    ...versionSelect,
                    version: response.versions[0]?.name,
                    precio: response.versions[0]?.price,
                    id: response.versions[0]?.id
                });

                var calculo = (response.price / 100) * 25;
                console.log("Tengo calculo:", calculo)
                setEnganche(calculo);
                setEnganchePrincipal(calculo);

                let obj = {
                    car_id: response.versions[0]?.id,
                    entry_percentage:  25, //Falta url porcentaje
                    months: '60',
                    warraty_id: 1,
                }
                //console.log("No hay nada: ",paramsSearch);
                api.createQuotation(obj).then((r) => {
                    /* console.log("Obtengo el pago mensual: ", r); */
                    setPagoMensual(r.data.payment.monthly_payment);
                }).catch((error) => console.log("Hay un error en el calculador de mensualidad: ", error));

                objParams = {
                    color: colorReplace,
                    background: '00',
                    version: versionNameReplace,
                    meses: '60',
                    porcentaje: 25,
                    ciudad: 'Guadalupe',
                    url: propsParamsClean === undefined ? "" : propsParamsClean[1],
                    tipos: splitPropsParams?.length >= 2 ? splitPropsParams[1] === "utm_source" ? `${splitPropsParams[1]}` : `${splitPropsParams[1]}` : "",
                    utm_source: source, //`&${splitPropsParams[1]}`
                    utm_medium: medium,
                    utm_campaign: campaign,
                    cnname: cnname,
                    utm_content: content,
                    utm_term: term,
                    gad_source: gadSource,
                    gclid: gclId
                }
            } else {
                /* console.log("Si tengo parametros, sucede lo siguiente: "); */
                let atobColor = atob(arrayParams[1]);
                let atobBackground = atob(arrayParams[2]);
                let atobVersion = atob(arrayParams[3]);
                let atobMeses = atob(arrayParams[4]);
                let atobPorcentaje = atob(arrayParams[5]);
                
                setColor(atobColor.replace(/-/g, " "));
                setBackground(atobBackground);

                let filterImageCotiza = response.colors.filter((obj, i) => obj.name === atobColor.replace(/-/g, " "));
                setImage(filterImageCotiza[0].image);

                let filterVersiones = response.versions.filter((obj, i) => obj.name === atobVersion.replace(/-/g, " "));
                /* console.log("filterVersiones: ", filterVersiones); */

                var calculo = (filterVersiones[0]?.price / 100) * parseInt(atobPorcentaje);
                setEnganche(calculo);
                setEnganchePrincipal(calculo);

                let obj = {
                    car_id: filterVersiones[0]?.id,
                    entry_percentage:  parseInt(atobPorcentaje), //Falta url porcentaje
                    months: atobMeses,
                    warraty_id: 1,
                }
    
                api.createQuotation(obj).then((r) => {
                    /* console.log("Obtengo el pago mensual: ", r); */
                    setPagoMensual(r.data.payment.monthly_payment);
                }).catch((error) => console.log("Hay un error en el calculador de mensualidad: ", error));

                setVersionSelect({
                    ...versionSelect,
                    version: filterVersiones[0]?.name,
                    precio: filterVersiones[0]?.price,
                    id: filterVersiones[0]?.id
                });

                setMesValor(parseInt(atobMeses));

                setValorPorcentaje(parseInt(atobPorcentaje));

                //console.log("Checando si existe UTM: ",  );
                
                objParams = {
                    color: atobColor,
                    background: atobBackground,
                    version: atobVersion,
                    meses: atobMeses,
                    porcentaje: atobPorcentaje,
                    ciudad: 'Guadalupe',
                    tipos: arrayParams[6] === "" ? "" :  arrayParams[6] === "utm_source" ? "" : `${arrayParams[6]}`,
                    utm_source: source, //`&${splitPropsParams[1]}`
                    utm_medium: medium,
                    utm_campaign: campaign,
                    cnname: cnname,
                    utm_content: content,
                    utm_term: term,
                    gad_source: gadSource,
                    gclid: gclId
                }

                //console.log("Tengo objParams;: ", objParams);
            }

            let constructUrlParams = `${source ? `utm_source=${source}` : ""}${medium ? `&utm_medium=${medium}` : ""}${campaign ? `&utm_campaign=${campaign}` : ""}${cnname ? `&cnname=${cnname}` : ""}${content ? `&utm_content=${content}` : ""}${term ? `&utm_term=${term}` : ""}${gadSource ? `&gad_source=${gadSource}` : ""}${gclId ? `&gclid=${gclId}` : ""}`
            
            setUrlContruct(propsParamsClean === undefined ? `&${constructUrlParams}` : `&${propsParamsClean[1]}`);
            
            setUrlNavigation(propsParamsClean === undefined ? `?${constructUrlParams}` : `?${propsParamsClean[1]}`);

            urlParametros(objParams);

            let obj = {
                car_id: response.versions[0]?.id,
                entry_percentage: 25,
                months: 60,
                warraty_id: 1,
            }
       
            api.createQuotation(obj).then((r) => {
                setMensualPrincipal(r.data.payment.monthly_payment);
                setCargaPMensual(true);
            }).catch((error) => console.log("Hay un error en el calculador de mensualidad: ", error));

        });
        //return () => window.removeEventListener("popstate", handleBackButton)
    },[]);

    //console.log(urlContruct, "LALALALA");
    // Funcion para parametros navigate

    function urlParametros(url, proviene){
        //console.log("Estamos en urlPArametros: ", url, proviene);
        if (arrayParams[1]=== undefined){
            arrayParams[1] = "";
        }
    
        if(proviene === "Color"){ //Colores
            let colorReplace = url.replace(/ /g, "-");
            let versionReplace = versionSelect.version?.replace(/ /g, "-");
            navigate(`?${btoa(colorReplace)}&${btoa(background)}&${btoa(versionReplace)}&${btoa(mesValor)}&${btoa(valorPorcentaje)}${urlContruct}`);

        } else if (proviene === "Version"){ //Version
            let colorReplace = color.replace(/ /g, "-");
            let versionReplace = url.replace(/ /g, "-");
            navigate(`?${btoa(colorReplace)}&${btoa(background)}&${btoa(versionReplace)}&${btoa(mesValor)}&${btoa(valorPorcentaje)}${urlContruct}`);
            
        } else if (proviene === "Meses"){ //Meses
            let colorReplace = color.replace(/ /g, "-");
            let versionReplace = versionSelect.version.replace(/ /g, "-");
            navigate(`?${btoa(colorReplace)}&${btoa(background)}&${btoa(versionReplace)}&${btoa(url)}&${btoa(valorPorcentaje)}${urlContruct}`);
            
        } else if (proviene === "Enganche"){ // Enganche
            let colorReplace = color.replace(/ /g, "-");
            let versionReplace = versionSelect?.version.replace(/ /g, "-");
            navigate(`?${btoa(colorReplace)}&${btoa(background)}&${btoa(versionReplace)}&${btoa(mesValor)}&${btoa(url)}${urlContruct}`);
            
        } else { // Creación por default
            let constructUrlParams = `${url?.utm_source ? `&utm_source=${url?.utm_source}` : ""}${url?.utm_medium ? `&utm_medium=${url?.utm_medium}` : ""}${url?.utm_campaing ? `&utm_campaign=${url?.utm_campaing}` : ""}${url?.cnname ? `&cnname=${url?.cnname}` : ""}${url?.utm_content ? `&utm_content=${url?.utm_content}` : ""}${url?.utm_term ? `&utm_term=${url?.utm_term}` : ""}${url?.gad_source ? `&gad_source=${url?.gad_source}` : ""}${url?.gclid ? `&gclid=${url?.gclid}` : ""}`
            //navigate(`?${btoa(url?.color)}&${btoa(url?.background)}&${btoa(url?.version)}&${btoa(url?.meses)}&${btoa(url?.porcentaje)}${url.tipos === "" ? "" : `&${url.tipos}`}${constructUrlParams}`);
            navigate(`?${btoa(url?.color)}&${btoa(url?.background)}&${btoa(url?.version)}&${btoa(url?.meses)}&${btoa(url?.porcentaje)}${url?.url === undefined ? constructUrlParams : `&${url?.url}`}`);

            //console.log("Preparar URL: ", url);

        }

    }
    
    function onColorChange(pintura) {

        let filterImageCotiza = car.colors.filter((obj, i) => obj.name === pintura);
        setImage(filterImageCotiza[0].image);

        setClickPagina(clickPagina + 1);
        setColor(pintura);
        urlParametros(pintura, "Color");
    }

    function onEngancheChange(porcentaje){  
     
        var calculo = (versionSelect.precio / 100) * porcentaje;
        setEnganche(calculo);
        setValorPorcentaje(porcentaje);
        urlParametros(porcentaje, "Enganche")
    }

    function onMensualidad(mes){
        setMesValor(mes)
        urlParametros(mes, "Meses")
    }

    function onVersionSelect(datos){
        let datosSelectSplit = datos.split(",");
        //console.log("Tengo version: ", datosSelectSplit)
        setVersionSelect({
            ...versionSelect,
            version: datosSelectSplit[1],
            precio: datosSelectSplit[2],
            id: datosSelectSplit[3]
        });

        var calculo = (datosSelectSplit[2] / 100) * valorPorcentaje;
        setEnganche(calculo);
        urlParametros( datosSelectSplit[1], "Version")
    }

    function onPagoMensualidad(){
        setCargaPMensual(false);
        let obj = {
            car_id: versionSelect.id,
            entry_percentage: valorPorcentaje,
            months: mesValor,
            warraty_id: 1,
          }        
          /* console.log("Pago mensualidad: ", obj) */
        api.createQuotation(obj).then((r) => {

            tagManagerArgs = {
                gtmId: 'GTM-NND5SRVW', //Este ID se obtiene en el admin de GTM - Cambiar a chevrolet
                dataLayer: {
                    'event': 'dd_cotiza_auto_nuevo',
                    'auto_marca': car.make,
                    'auto': car.model,
                    'auto_ano': car.year,
                    'auto_version': versionSelect.version,
                }
            }
            //console.log(tagManagerArgs);
            TagManager.initialize(tagManagerArgs);
            setPagoMensual(r.data.payment.monthly_payment);   
            setCargaPMensual(true);
          }).catch((error) => console.log("Hay un error en el calculador de mensualidad: ", error));
    }

    function onPageReloadNavigate(slug){
        //console.log("ReloadPage", `/${car?.make}/${slug}${paramsPublicidad ? `&${paramsPublicidad}` : ``}`) 
        navigate(`/nuevos/${slug}`, {state: {paramsPublicidad: urlContruct}});

        setTimeout(() => {
            window.location.reload(false);
        }, 600)
    } 
    //Funcion para la barra de navegación
    //Params : parameters={paramsURL.utm_source === undefined ? `` : `${paramsURL.cid === undefined ? "" : `?${paramsURL.cid}`}${paramsURL.ucid === undefined ? "" : `&${paramsURL.ucid}`}`}
    //console.log("Tengo lo siguiente autosLanding paramsUTM: ", paramsURL);
    function barraNavegacion(e){

        //setNavActivo(e)

        if(e === "Principal"){refPrincipal.current.scrollIntoView({ block: 'start',  behavior: 'smooth' }); setNavActivo("Principal");}
        if(e === "Cotiza en línea"){refCotiza.current.scrollIntoView({ block: 'start',  behavior: 'smooth' }); setNavActivo("Cotiza en línea")}
        if(e === "Características"){refCaracteristicas.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })}
        if(e === "Galería"){refGaleria.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })}
        if(e === "Versiones"){refVersiones.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })}

        if(e.target){
      
            if (e.target.scrollTop === refPrincipal.current.offsetTop) { setNavActivo("Principal");} 
            else if (e.target.scrollTop <= refCotiza.current.offsetTop) { setNavActivo("Cotiza en línea");} 
            else if (e.target.scrollTop <= refCaracteristicas.current.offsetTop) { setNavActivo("Características"); } 
            else if (e.target.scrollTop <= refGaleria.current.offsetTop) { setNavActivo("Galería"); } 
            else if (e.target.scrollTop <= refVersiones.current.offsetTop) { setNavActivo("Versiones"); }
        }
        
    }

        //parameters={urlContruct === "?undefined" ? "" : urlContruct} sdf={paramsURL.utm_source === undefined ? `` : `${paramsURL.cid === undefined ? "" : `?${paramsURL.cid}`}${paramsURL.ucid === undefined ? "" : `&${paramsURL.ucid}`}`}
    return(
        <Layout selectedMenuItem={"nuevos"} barraNavegacion={barraNavegacion} navActivo={navActivo} parameters={urlContruct === "&undefined" ? "" : urlNavigation}>
            <SEO
                title={`${
                    car?.make.toLowerCase() ? car?.make.toLowerCase()  + ` ` + car?.model.toLowerCase()  + ` ` + car?.year  : ""
                } | Chevrolet Rivero - Chevrolet Agencia Monterrey`}
                description={`${
                    car?.model.toLowerCase() ? car?.model.toLowerCase() : ""
                } - Chevrolet Rivero, Agencia Chevrolet Monterrey  Aveo, Beat, Spark, Onix, Cavalier, Trax - venta autos nuevos y seminuevos - Chevrolet Guadalupe, Santa Catarina, LindaVista`}
                keywords={`${
                    car?.make ? car?.make + ` ` + car?.model + ` ` + car?.year : ""
                },  Chevrolet Rivero, Agencia Chevrolet Monterrey,agencia chevrolet, autos seminuevos, carro nuevo, carro chevrolet`}
                make={car?.make ? car?.make : ""}
                model={car?.model ? car?.model : ""}
                year={car?.year ? car?.year : ""}
                image={car ? car.image : ""}
                precio={car?.price ? car?.price : "180000"}
                sku={car ? car.id : ""}
                offer={car ? car.id : ""}
                details={car?.name ? car?.name : ""}
                path={`nuevos/${car ? car.slug : ""}`}
            />
            <div className="container-landing" onScroll={(e) => barraNavegacion(e)} ref={refContainer}>
                <Container fluid style={{maxWidth: 1400}} >
                    <div className="scroll-componentes" ref={refPrincipal}>
                        <Principal /* Datos tomados de Cotiza */ valorPorcentaje={valorPorcentaje} enganche={enganchePrincipal} mesValor={mesValor} versionSelect={versionSelect} pagoMensual={pagoMensual}/* Datos de Principal */ /*Promocion*/ setPromocionTabSelect={setPromocionTabSelect} promocion={promocion} /*Promocion*/ background={background} setShowModal={setShowModal} showModal={showModal} colores={car?.colors} colorSelect={color} modelo={car?.model} anio={car?.year} anios={car?.years} precio={car?.price} onColorChange={onColorChange} menualPrincipal={menualPrincipal} onPageReloadNavigate={onPageReloadNavigate}
                            /*Falta parametro parameters*/ />
                    </div>
                    <div className="scroll-componentes" ref={refCotiza}>
                        <div className="scroll-identificador"></div>
                        <Cotiza setShowModal={setShowModal} showModal={showModal} valorPorcentaje={valorPorcentaje} cargaPMensual={cargaPMensual} versionSelect={versionSelect} onVersionSelect={onVersionSelect} mesValor={mesValor} onMensualidad={onMensualidad} pagoMensual={pagoMensual} onPagoMensualidad={onPagoMensualidad} onEngancheChange={onEngancheChange} imagenColor={imagen} versiones={car?.versions} modelo={car?.model} anio={car?.year} precio={car?.price} enganche={enganche}/>
                    </div>
                    <div className="scroll-componentes" ref={refCaracteristicas} hidden>
                        <div className="scroll-identificador"></div>
                        <Caracteristicas modelo={car?.model} anio={car?.year} videoIntera={car?.interactive_video}/>
                    </div>
                    <div className="scroll-componentes" ref={refGaleria}>
                        <div className="scroll-identificador"></div>
                        <Galeria modelo={car?.model} anio={car?.year} fotos={car?.gallery} video={car?.videos}/>
                    </div>
                    <div className="scroll-componentes" ref={refVersiones} >
                        <div className="scroll-identificador"></div>
                        <Versiones setVerFeatur={setVerFeatur} verFeatur={verFeatur} modelo={car?.model} anio={car?.year} versiones={car?.versions} features={featuresVersion} setFeaturesVersion={setFeaturesVersion}/>
                    </div>
                    <div className="scroll-componentes-tecnica">
                        <div className="scroll-identificador"></div>
                        <InformacionTecnica specsUrl={specsUrl} manualUrl={manualUrl} catalogUrl={catalogUrl} car={car}/>
                    </div>
                </Container>
             {/*   <Container fluid style={{maxWidth: 1400, display: 'flex', justifyContent: 'flex-end'}}>
                    <BotonesNav barraNavegacion={barraNavegacion} navActivo={navActivo}/>
                </Container>*/}
                <div className="scroll-componentes-footer">
                    <div className="scroll-identificador"></div>
                    <Footer navActivo={navActivo}/>
                </div>
            </div>
            <ModalSalesforce promocionTabSelect={promocionTabSelect} promocion={promocion} show={showModal} onHide={() => setShowModal(false)} props={props} paramsURL={paramsURL} car={car} setVersionSelect={setVersionSelect} valorPorcentaje={valorPorcentaje} mesValor={mesValor} pagoMensual={pagoMensual} versionSelect={versionSelect}/>
        </Layout>
    )
}

function ModalSalesforce({props,promocionTabSelect, promocion, show, onHide, car, valorPorcentaje, mesValor, pagoMensual, versionSelect, paramsURL}){
    const [ versionSeleccionada, setVersionSeleccionada ] = useState();
    //console.log("Tengo promocionTabSelect: ", show);
    let splitVersion = versionSeleccionada?.split(/-/, 2);

    let objectCar = {
        make: car?.make,
        model: car?.model,
        year: car?.year,
        fullauto: `${car?.make} ${car?.model} ${car?.year}`
    }

    return(
        <Modal show={show.show} onHide={onHide} centered>
            <Modal.Header closeButton>
                Contactar Asesor
            </Modal.Header>
            <Modal.Body>
                <FormularioSalesforce props={props} infoCar={objectCar} versionSplit={splitVersion} versionSelect={versionSelect.version} pagina="detalle-de-nuevos" paramsURL={paramsURL}>
                    {show.proviene === "Principal" ? 
                        <>
                            <label htmlFor="contactoReferencia">
                                Elige la versión de tu 
                            </label>
                    
                            <select
                                className="form-control"
                                name="version"
                                id="version"
                                aria-describedby="version"
                                onChange={(e) => {setVersionSeleccionada(e.target.value)}}
                                value={versionSeleccionada}
                                >
                                {car
                                ? car.versions.map((version) => {
                                    return (
                                        <option value={`${version.name} - ${version.price}`} key={version.id}>
                                        {version.name} $ <NumericFormat displayType="text" value={version.price} allowLeadingZeros thousandSeparator="," />
                                        </option>
                                    );
                                    })
                                : null}
                            </select>
                        </>
                        : null
                    }

                    <input hidden id="00Nf400000UBhZt" name="00Nf400000UBhZt" value="Nuevos" />
                    <input hidden id="00N8a00000FPBY3" name="00N8a00000FPBY3" value={car?.sf_id} />
                    <input hidden value={splitVersion ? splitVersion[0] : versionSelect.version} name="00Nf400000UBha2" id="00Nf400000UBha2" readOnly={true} />
                    <input hidden value={splitVersion ? splitVersion[1] : versionSelect.precio} name="00Nf400000UBha1" id="00Nf400000UBha1" readOnly={true} />

                    <input hidden id="00Nf400000UBhZ5" name="00Nf400000UBhZ5" value={
                                        promocion ? promocionTabSelect === "PROMOCION" ? 
                                            promocion.titulo2 : "" 
                                        : 
                                        show.proviene === "Cotiza" ? `Versión ${versionSelect.version}` : "Versión básica"
                                        } /> {/**Comentarios para promociones */}

                    <input hidden id="00Nf400000UBhZB" name="00Nf400000UBhZB" value={valorPorcentaje || 25} />

                    <input hidden id="00Nf400000UBhZh" name="00Nf400000UBhZh" value={mesValor || "60"}/>
                    
                    <input hidden id="00Nf400000UBhZj" name="00Nf400000UBhZj" value={pagoMensual || null}/>

                </FormularioSalesforce>
            </Modal.Body>
        </Modal>
    )
}