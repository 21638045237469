import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, withPrefix } from "gatsby";
import TagManager from 'react-gtm-module';

import * as stylesFooter from "../styles/styles-header-footer.module.css";

import Image from "./widgets/basico/image";

export default function Footer ({navActivo, params, marca}){
    
    const [ colorHeader, setColorHeader ] = useState();
    const [ textFooter, setTextFooter ] = useState();
    const [ logoRiveroFooter, setLogoRiveroFooter ] = useState();
    const [ empresa, setEmpresa ] = useState("Chevrolet");
    const [ visualKrino, setVisualKrino ] = useState(true);
    const [ visualIcons, setVisualIcons ] = useState();
    const [ redSocial, setRedSocial ] = useState();

    const socialIconsChevrolet = [
        { link: "https://www.facebook.com/agenciarivero", icon: "boton_footer_blanco_facebook.png", nombre: "facebook"  },
        { link: "https://www.youtube.com/gruporivero", icon: "boton_footer_blanco_youtube.png", nombre: "youtube" },
        { link: "https://www.instagram.com/gruporivero/", icon: "boton_footer_blanco_instagram.png", nombre: "instagram"  },
        { link: "https://twitter.com/agenciarivero", icon: "boton_footer_blanco_x.png", nombre: "x" },
    ];

    const socialIconsNissan = [
        { link: "https://www.facebook.com/profile.php?id=61551072302727", icon: "boton_footer_blanco_facebook.png", nombre: "facebook"  },
        { link: "https://www.instagram.com/nissanrivero/", icon: "boton_footer_blanco_instagram.png", nombre: "instagram"  },
        { link: "https://twitter.com/agenciarivero", icon: "boton_footer_blanco_x.png", nombre: "x" },
    ];
 
    useEffect(() => {
        let splitUrl = window.location.pathname.split("/");
        //console.log("URL: ", splitUrl);
        if(navActivo === "Principal" || navActivo === "Cotiza en línea" || navActivo === "Características"|| navActivo === "Galería"|| navActivo === "Versiones"){
            setVisualKrino(false);
            setVisualIcons(false);
            setEmpresa("principal");
            setColorHeader("footerBackChevrolet");
            setTextFooter("footertextChevrolet");
        } else if (splitUrl[1] === "gmc"){
            setColorHeader("footerBackLujo");  
            setTextFooter("footertextLujo");
            console.log("Tengo Split: ", splitUrl[1])
        } else if (splitUrl[1] === "buick"){
            setColorHeader("footerBackLujo");  
            setTextFooter("footertextLujo");
            console.log("Tengo Split: ", splitUrl[1])
        } else if (splitUrl[1] === "cadillac"){
            setColorHeader("footerBackLujo");  
            setTextFooter("footertextLujo");
            console.log("Tengo Split: ", splitUrl[1])
        } else {
            setVisualKrino(false);
            setVisualIcons(false);
            setEmpresa("chevrolet");
            setColorHeader("footerBackChevrolet");
            setTextFooter("footertextChevrolet");
        }
    })

    let tagManagerArgs = {
        gtmId: 'GTM-W7HQJGC6', //Este ID se obtiene en el admin de GTM
        dataLayer: {
            'event': redSocial,
        }
    }
    useEffect (() => {
        if (tagManagerArgs.dataLayer.event != undefined){
            //console.log(tagManagerArgs, "Analytics");
            TagManager.initialize(tagManagerArgs);
        }
    }, [tagManagerArgs]);

    function sendData(red){ 
        setRedSocial('dd_'+red+'_footer');
    }

    const mappingReSocialesChevrolet = socialIconsChevrolet?.map((obj, i) => {

        return(
            <Col key={i} className="p-1">
                <a onClick={() => sendData(obj.nombre)} href={obj.link} target="_blank" rel="noreferrer" className={`footericon ${obj.nombre}`}> 
                    <Image
                        className="d-md-block d-none"
                        fileName={obj.icon}
                        alt={`iconos social chevrolet rivero ${obj.nombre}`}
                        imgStyles={{width: "100%" }}
                        style={{width: "45%" }}
                    /> 
                </a>
            </Col>
        )
    })

    return(
        <div className={`d-lg-block d-none ${colorHeader}`} >
            <Container>
                <Row>
                    <Col lg={2}>
                        <div className={stylesFooter.footerContainerImage}>
                            <Image
                                fileName="logo_basico.png"
                                alt="banner"
                                imgStyles={{width: "100%" }}
                                style={{width: "100%" }}
                            />
                        </div>
                    </Col>
                    <Col lg={7}>
                        <p className={`${textFooter} mt-1`}>
                            <div>Copyright © 2024 Grupo Rivero <br/></div>
                            Powered By Grupo Rivero. Av. Miguel Aleman #5400 <br/>
                            Torres de Linda Vista, Guadalupe, Nuevo León, 67126, México.
                        </p>
                    </Col>
                    <Col lg={3}>
                        <Row className="pt-2">
                            {mappingReSocialesChevrolet}
                        </Row>
                        <Row className="pt-2" style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link
                                to={params ? withPrefix("aviso-de-privacidad") + params : withPrefix("aviso-de-privacidad")}
                                className={stylesFooter.footertextInfo}
                                >
                                Aviso de privacidad
                            </Link>
                            <Link
                                to={params ? withPrefix("terminos-y-condiciones") + params : withPrefix("terminos-y-condiciones")}
                                className={stylesFooter.footertextInfo}
                                >
                                Terminos y Condiciones
                            </Link>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

/**
 * ,
 *             { visualKrino === true ? (
                <div style={{backgroundColor: 'salmon', position: 'absolute', right: 0, top: 250}}>Kirino</div>
            ) : null}
 * 
 * <script src="https://improvebot.krino.ai/065c58d980082ccfbb6901f39b119abd2b075cf05064b7e7f485fc25ed9ec4f5/EobkUbtqLSQYAVkteTWQEVeTR/?number=+528141610969&message=Hola&position=mid-right&css" async=""></script>
 * <div className={colorHeader}>
                    <Container>
                        <Row className="redesSocialesFooter">
                            {mappingRedesSociales}
                        </Row>
                        <Row className="copy">
                            <Col lg={6} md={4}>
                                <p style={{padding: 0, cursor: 'default'}} className="footertext">©Copyright 2020 Grupo Rivero</p>
                            </Col>
                            <Col lg={6} md={8}>
                                <Link className="footertext" to={withPrefix("aviso-de-privacidad")}>Aviso de privacidad</Link>
                                <Link className="footertext" to={withPrefix("terminos-y-condiciones")}>Términos y condiciones</Link>
                                <Link className="footertext" to={withPrefix("contacto")}>Contacto</Link>
                            </Col>
                        </Row>
                    </Container>
                </div>
 */