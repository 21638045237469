import React, { useState, useEffect, Fragment, useCallback, lazy } from 'react';
import { Row, Col, Container, Table, Button, Modal } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import { BreadCumbCar } from '../../components/widgets/breadCrumb';
import { Link } from 'gatsby';

import api from "../../actions/riveroApi";
import SEO from '../../components/seo';
import { ShimmerCarTextosLujo } from '../../components/widgets/miscelaneos';
import Image360 from "../../images/icons/imagen-360.png";
import FormularioVentaSalesforce from '../../components/widgets/formularios/FormularioVentaNissan';

const Layout = lazy(() => import('../../components/layouts/layout'));
const Visor360 = lazy(() => import('../../components/widgets/basico/visor360'));
const Image = lazy(() => import('../../components/widgets/basico/image'));

const THUMB_WIDTH = 100;
const THUMB_HEIGHT = 80;

let titleBtn = "Contactar Asesor";

export default function Detalles(props) {
    const parameters = props.location.search;
    //console.log("Tengo parameters en seminuevos: ", parameters);
    const [car, setCar] = useState({});
    const [showPreguntar, setShowPreguntar] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [sucursalId, setSucursalId] = useState("1043193");
    const { slug } = props.params;
    const carId = parseInt(slug ? slug.split("-").pop() : "");

    const renderData = (fn) => {
        if (isLoading) {
        return <div>Cargando...</div>;
        } else {
        return fn();
        }
    };

    useEffect(() => {
        api
        .getSeminuevosCarId(carId)
        .then((r) => {
            setCar(r.data);
            setIsLoading(false);

            let mode

            if (
            r.data.gallery &&
            r.data.gallery.length > 0 &&
            r.data.panoramic.length > 0
            ) {
            mode = "panoramic";
            } else if (r.data.gallery && r.data.gallery.length > 0) {
            mode = "gallery";
            } else {
            mode = "picture";
            }

            console.log(r.data.sucursal, "errrrr")

            if (r.data.sucursal.indexOf("RIVERO LINDA VISTA") !== -1) {
                setSucursalId("1043193");
            } else if (r.data.sucursal.indexOf("RIVERO GUADALUPE") !== -1) {
                setSucursalId("232812511");
            } else if (r.data.sucursal.indexOf("RIVERO SANTA CATARINA") !== -1) {
                setSucursalId("402145072");
            } else if (r.data.sucursal.indexOf("RIVERO HUMBERTO LOBO") !== -1) {
                setSucursalId("382179229");
            } else if (r.data.sucursal.indexOf("RIVERO GOMEZ MORIN") !== -1) {
                setSucursalId("115281495");
            } else if (r.data.sucursal.indexOf("RIVERO CONTRY") !== -1) {
                setSucursalId("1043194");
            } else if (r.data.sucursal.indexOf("RIVERO LAS TORRES") !== -1) {
                setSucursalId("599457775");
            } else if (r.data.sucursal.indexOf("RIVERO VALLE") !== -1) {
                setSucursalId("279130042");
            } else {
                setSucursalId("1043193");
            }
        })
        .catch((error) => {
            console.error(error);
            setIsLoading(false);
        });
    }, [carId]);

    //const iframeSrc = `https://www.gruporivero.com/commun/visor-360/index-jpg.php?serie=${car.id}`;
    const title = car.marca ? `${car.marca} ${car.nombre} ${car.year}` : "";
    const imagen = `https://riverorenta.mx/seminuevos/images/vista-360/${car.id}/imagen_1.jpg`;

return (
        <Layout parameters={parameters}>
            {car ? (
                <SEO
                title={`${title ? title : ""} Grupo Rivero - Seminuevos`}
                description={`${
                    title ? title : ""
                } - Grupo Rivero, Agencia Chevrolet Monterrey  Aveo, Beat, Spark, Onix, Cavalier, Trax - venta autos nuevos y seminuevos - Chevrolet Guadalupe, Santa Catarina, LindaVista`}
                keywords={`${
                    title ? title : ""
                },  Grupo Rivero, Agencia Chevrolet Monterrey,agencia chevrolet, autos seminuevos`}
                make={car ? car.marca : ""}
                model={car ? car.nombre : ""}
                year={car ? car.year : ""}
                image={imagen ? imagen : ""}
                precio={car ? car.precio : "120000"}
                sku={car ? car.id : ""}
                offer={car ? car.id : ""}
                details=""
                path={`/seminuevos/${props.pathname?.split("/")[2]}`}
                />
            ) : null}

            {<Container  style={{paddingBottom: 55}}>
                <Row className="pt-2">
                    <Col>
                        <BreadCumbCar parameters={parameters} car={car} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={6} sm={6} className="text-light">
                        <h1 className="text-white">
                        {!isLoading ? title : <ShimmerCarTextosLujo width={'100%'} height={20} color="rgba(255, 255, 255, 0.15)"/>}
                        </h1>
                        #{car ? car.id : null} - VIN: {car ? car.vin : null}
                    </Col>
                    <Col lg={6} md={6} sm={6} style={{ textAlign: "right" }}  className="text-light" >
                        Precio de contado:
                        <div style={{ textAlign: "right", color: "#4d99d3", fontSize: "30px", fontWeight: "bold", lineHeight: "28px"}} >
                        $
                        {!isLoading ? (
                            <NumericFormat displayType="text" value={car?.precio} allowLeadingZeros thousandSeparator="," decimalScale={2} />
                        ) : (
                            <ShimmerCarTextosLujo width={'100%'} height={20} color="rgba(255, 255, 255, 0.15)"/>
                        )}
                        <span> MXN</span>
                        </div>
                    </Col>
                </Row>

                <Row className="pt-4 pb-2">
                    <Col xl={8} lg={8} md={12} sm={12} className="image mb-3">
                        <div>
                            <CarGallery car={car} mode="panoramic" />
                        </div>
                    </Col>

                    <Col xl={4} lg={4} md={12} sm={12} className="image">
                        <Table bordered={true} className="dark-table leaded rounded rounded-sm" >
                            <tbody style={{ fontSize: "12px" }}>
                                {car.sucursal ? (
                                <tr>
                                    <td>Agencia</td>
                                    <td>{renderData(() => car.sucursal)}</td>
                                </tr>
                                ) : null}

                                {car.trans ? (
                                <tr>
                                    <td>Transmisión</td>
                                    <td>{renderData(() => car.trans)}</td>
                                </tr>
                                ) : null}
                                {car.color ? (
                                <tr>
                                    <td>Color</td>
                                    <td>{renderData(() => car.color)}</td>
                                </tr>
                                ) : null}
                                {car.km ? (
                                <tr>
                                    <td>Km</td>
                                    <td>{renderData(() => <NumericFormat displayType="text" value={car?.km} allowLeadingZeros thousandSeparator="," decimalScale={0} />)}</td>
                                </tr>
                                ) : null}
                                {car.clima ? (
                                <tr>
                                    <td>Clima</td>
                                    <td>{renderData(() => (car.clima ? car.clima : null))}</td>
                                </tr>
                                ) : null}
                                {car.rines ? (
                                <tr>
                                    <td>Rines</td>
                                    <td>{renderData(() => (car.rines ? car.rines : null))}</td>
                                </tr>
                                ) : null}
                                {car.vidrios ? (
                                <tr>
                                    <td>Vidrios Eléctricos</td>
                                    <td>
                                    {renderData(() => (car.vidrios ? car.vidrios : null))}
                                    </td>
                                </tr>
                                ) : null}
                                {car.motor ? (
                                <tr>
                                    <td>Motor</td>
                                    <td>{renderData(() => (car.motor ? car.motor : null))}</td>
                                </tr>
                                ) : null}

                                {car.descripcion ? (
                                <tr>
                                    <td
                                    colSpan="2"
                                    style={{ lineHeight: "13px", fontFamily: "Lato" }}
                                    className="text-light"
                                    >
                                    <strong>{renderData(() => car.descripcion)}</strong>
                                    </td>
                                </tr>
                                ) : null}
                            </tbody>
                        </Table>
                        <Col xs={12}>
                            <Row className="mb-4">
                                {!isLoading ? (
                                    <Col xl={12} lg={12} md={12} sm={12}>
                                        <div className="boton boton-secundario p-3 mb-0 w-100"
                                            role="button"
                                            tabIndex={0}
                                            style={{ fontSize: "16px" }}
                                            onClick={() => setShowPreguntar(true)}>
                                                {titleBtn}
                                        </div>
                                    </Col>
                                ) : (
                                    <div>Aqui hay boton</div>
                                )}
                            </Row>
                        </Col>
                    </Col>
                </Row>

                <Row className="pt-4 pb-4">
                    <Col >
                        <Link to={`/chevrolet/catalogo/nuevos`} >
                            <Image
                                className="d-md-block d-none"
                                fileName={`similares_nuevos.png`}
                                alt="Catalogos Nuevos"
                                imgStyles={{height: "100%" }}
                                style={{height: "100%" }}
                            />
                        </Link>
                    </Col>
                </Row>
            </Container>}
            <ModalSalesforce show={showPreguntar} onHide={() => setShowPreguntar(false)} props={props} car={car} sucursalId={sucursalId} title={title} parameters={parameters}/>
        </Layout>
    );
}

function ModalSalesforce({show, onHide, props, car, sucursalId, title, parameters}){

    console.log("car", title);

    let objectCar = {
        make: car.marca,
        model: car.nombre,
        year: car.year,
        fullauto: title,
        sucursal: car.sucursal
    }

    return(
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <h5>Contactar asesor</h5>
            </Modal.Header>
            <Modal.Body>
                <FormularioVentaSalesforce 
                infoCar={objectCar}
                sucursalId={sucursalId}
                props={props} pagina="detalle-seminuevos"
                parameters={parameters}
                >

                <input hidden id="00Nf400000UBhZt" name="00Nf400000UBhZt" value="Seminuevos" />
                <input hidden id="00Nf400000UBha1" name="00Nf400000UBha1" value={car?.precio} readOnly={true} />
                <input hidden id="00Nf400000UBhYt" name="00Nf400000UBhYt" value={sucursalId} />
                <input hidden id="00N8a00000FPBY3" name="00N8a00000FPBY3" value={car ? (car.sf_id ? car.sf_id : null) : null} />
                <input hidden id="00Nf400000UBhZ5" name="00Nf400000UBhZ5" value={
                    car ? "VIN: " + car.vin + ", precio: $" + car.precio + ", serie:" + car.id + ", color:" + car.color : null } />
                
                </FormularioVentaSalesforce>
            </Modal.Body>
        </Modal>
    )
}

function CarGallery({ car, loading }) {
    const firstPanoramic =
        car && car.panoramic && car.panoramic.length > 0 ? car.panoramic[0] : null;
    const firstGallery =
        car && car.gallery && car.gallery.length > 0 ? car.gallery[0] : null;

    const [mode, setMode] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    const handleSelectThumbnail = useCallback(({ imageUrl, imageMode }) => {
        setImageUrl(imageUrl);
        setMode(imageMode);
    }, []);

    useEffect(() => {
        setMode(firstPanoramic ? "panoramic" : "gallery");
        setImageUrl(
        firstGallery ? firstGallery : car && car.picture ? car.picture : null
        );
    }, [car]);

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (!car) {
        return null;
    }

    return (
        <Fragment>
        {mode === "gallery" ? <CarPicture imageUrl={imageUrl} /> : null}
        {mode === "panoramic" ? <CarVisor360 car={car} /> : null}
        {mode === "vistaCarros" ?   <picture>
                                            <img
                                                src={imageUrl}
                                                alt={`imagen seminuevos`}
                                                style={{ width: '100%' }}
                                            />
                                        </picture> : null}
        <div style={{ overflow: "visible" }}>
            {firstPanoramic ? (
            <Thumbnail
                src={firstPanoramic}
                selected={mode === "panoramic"}
                mode="panoramic"
                overlay={
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                    backgroundColor: "rgba(0,0,0,0.5)",
                    position: "absolute",
                    width: THUMB_WIDTH,
                    height: THUMB_HEIGHT,
                    }}
                >
                    <img src={Image360} style={{ width: 64 }} alt={car} />
                </div>
                }
                onSelect={handleSelectThumbnail}
            />
            ) : null}
            {car && car?.panoramic?.map((obj, i) => {
                return(
                    <Thumbnail
                        key={i}
                        src={obj}
                        selected={mode === "vistaCarros" && imageUrl === obj}
                        onSelect={handleSelectThumbnail}
                        mode="vistaCarros"
                        overlay={
                            <div 
                                style={{
                                    position:"absolute",
                                    width:THUMB_WIDTH,
                                    height: THUMB_HEIGHT,
                                }}
                            />
                        }
                    />
                )
            })}
            {car && car.gallery
            ? car.gallery.map((i, index) => (
                <Thumbnail
                    src={i}
                    key={index}
                    mode="gallery"
                    selected={mode === "gallery" && imageUrl === i}
                    onSelect={handleSelectThumbnail}
                />
                ))
            : null}
        </div>
        </Fragment>
    );
}

function CarPicture({ car, imageUrl }) {
    const src = imageUrl ? imageUrl : car && car.picture ? car.picture : null;
    return (
        <div
        style={{
            backgroundImage: `url(${src})`,
            backgroundSize: "100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: 400,
            width: "100%",
        }}
        rol="img"
        id="ImgPrincipal"
        arial-label={`${
            car
            ? car.marca +
                " " +
                car.nombre +
                " " +
                car.year +
                " Trans " +
                car.trans +
                " - Seminuevos a credito " +
                car.color
            : null
        }`}
        />
    );
}

function CarVisor360({ car }) {
    if (!car || !car.panoramic) {
        return null;
    }

    return (
        <Visor360
        images={car.panoramic}
        alt={`${
            car
            ? car.marca +
                " " +
                car.nombre +
                " " +
                car.year +
                " Trans " +
                car.trans +
                " - Seminuevos a credito " +
                car.color
            : null
        }`}
        />
    );
}

function Thumbnail({ src, overlay, selected, onSelect, mode }) {
    return (
        <button
        className="p-0"
        style={{
            width: THUMB_WIDTH,
            height: THUMB_HEIGHT,
            overflow: "hidden",
            boxSizing: "content-box",
            borderStyle: "solid",
            borderColor: selected ? "white" : "#33373a",
            position: 'relative'
        }}
        onClick={(_) => onSelect({ imageUrl: src, imageMode: mode })}
        >
        {overlay ? overlay : null}
        <img
            src={src}
            alt="default"
            style={{
            width: THUMB_WIDTH,
            height: THUMB_HEIGHT,
            objectFit: "cover",
            }}
        />
        </button>
    );
}
